<template>
    <div>
        <section class="blog-page-section">
            <div class="blog-content-wrapper">
                <BlogPost></BlogPost>
            </div>
        </section>
    </div>
</template>

<script>
import BlogPost from "../components/blog/BlogPost.vue";

export default {
    components: { BlogPost }
}
</script>

<style scoped>

</style>