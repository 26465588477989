<template>
    <div>
        <section class="blog-post-section">
            <!-- Back Button -->
            <div class="back-button">
                <router-link :to="{ name: 'blogs' }" class="back-button-wrapper">
                    <div class="back-button-icon">
                        <i class="bi bi-arrow-left-short"></i>
                    </div>
                    <div class="back-button-text">Blogs</div>
                </router-link>
            </div>
            <!-- Blog -->
            <div class="blog-post-title">{{blogData.title}}</div>
            <div class="blog-post-author-date-wrapper">
                <div class="blog-post-author">{{blogData.author}}</div>
                <div class="round-dot"></div>
                <div class="blog-post-date">{{blogData.date}}</div>
            </div>
            <div class="blog-post-tags flex align-center justify-center">
                <div class="tag" v-for="tag in blogData.category" :key="tag">
                    {{tag}}
                </div>
            </div>
            <div class="blog-post">                
                <div class="blog-post-image-wrapper">
                    <div class="blog-post-image">
                        <img :src="blogData.image" alt="illapa cloud blog cover image">
                    </div>
                </div>
                <div class="blog-post-body" v-html="blogData.body"></div>
            </div>
            <!-- Socials -->
            <div class="blog-post-socials-wrapper">
                <a href="https://twitter.com/IllapaCloud" class="social-icon twitter" target="_blank" aria-label="Illapa Twitter">
                    <i class="bi bi-twitter"></i>
                </a>
                <a href="https://www.linkedin.com/company/illapa" class="social-icon linkedin" target="_blank" aria-label="Illapa LinkedIn">
                    <i class="bi bi-linkedin"></i>
                </a>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    data: () => ({
        blogData: {
            id: "",
            title: "",
            body: "",
            author: "",
            category: [],
            date: "",
            image: "",
        }
    }),
    metaInfo() {
        return {
            title: `${this.blogData.title} | Illapa Cloud`,
            meta: [
                {
                    name: 'description',
                    content: this.blogData.body.slice(0, 100)
                },
                {
                    property: 'og:title',
                    content: `${this.blogData.title} | Illapa Cloud`
                },
                {
                    property: 'og:description',
                    content: this.blogData.body.slice(0, 100)
                },
                {
                    property: 'og:url',
                    content: `https://illapa.cloud/resources/blog/${this.blogData.id}`
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: `https://illapa.cloud/resources/blog/${this.blogData.id}`
                }
            ]
        }
    },
    mounted() {
        this.GetBlogData();
    },
    methods: {
        async GetBlogData() {
            const id = this.$route.params.id;
            try {
                const getBlog = await axios.get(`https://${location.host}/api/blogs/${id}`);
                // console.log(getBlog);

                if (getBlog.status === 200) {
                    this.blogData.id = id;
                    this.blogData.title = getBlog.data.title;
                    this.blogData.body = getBlog.data.body;
                    this.blogData.author = getBlog.data.author;
                    this.blogData.category = getBlog.data.category.split(", ");
                    this.blogData.date = this.FormatDate(getBlog.data.date);
                    
                    if(getBlog.data.image) {
                        this.blogData.image = 'data:image/jpeg;base64,' + getBlog.data.image;
                    }
                    else {
                        this.blogData.image = "/images/blog-cover-image-placeholder.png";
                    }
                }

            } catch (error) {
                console.log(new Error(error));
                if (error.response.status === 404 || error.response.status === 400) {
                    this.$router.push({ name: 'blogs' });
                }
            }
        },
        FormatDate(date) {
            const newDate = dayjs(new Date(date)).format("Do MMMM YYYY");
            return newDate;
        }
    },
}
</script>

<style scoped>
.blog-post-section {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 100px 20px;
}
.blog-post-section .back-button {
    max-width: 1024px;
    margin: 0 auto 20px auto;
}
.blog-post-section .back-button-wrapper {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: fit-content;
}
.blog-post-section .back-button-wrapper .back-button-icon {
    margin-right: 5px;
    font-size: 20px;
}
.blog-post-section .back-button-wrapper .back-button-text {
    font-size: 20px;
}
.blog-post-section .blog-post-title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}
.blog-post-section .blog-post-tags .tag {
    display: inline-block;
    padding: 10px 15px;
    background-color: rgba(0, 188, 197, 0.1);
    text-transform: capitalize;
    color: var(--brand-primary-blue);
    font-weight: 500;
    margin-right: 10px;
    border-radius: 50px;
}
.blog-post-section .blog-post-author-date-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.blog-post-section .blog-post-author-date-wrapper .blog-post-author,
.blog-post-section .blog-post-author-date-wrapper .blog-post-date {
    color: var(--text-dark-secondary);
}
.blog-post-section .blog-post-author-date-wrapper .round-dot {
    width: 5px;
    height: 5px;
    background: var(--text-dark-terniary);
    border-radius: 50%;
    margin: 0 10px;
}
.blog-post-section .blog-post {
    margin-bottom: 40px;
}
.blog-post-section .blog-post .blog-post-image-wrapper {
    margin-bottom: 60px;
}
.blog-post-section .blog-post .blog-post-image-wrapper .blog-post-image {
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 60px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog-post-section .blog-post .blog-post-image-wrapper .blog-post-image::before {
    content: "";
    width: 250px;
    height: 250px;
    background-image: url("../../assets/mission-image-dots.svg");
    position: absolute;
    top: -60px;
    left: -60px;
}
.blog-post-section .blog-post .blog-post-image-wrapper .blog-post-image::after {
    content: "";
    width: 150px;
    height: 150px;
    background-image: url("../../assets/mission-image-dots.svg");
    position: absolute;
    bottom: -40px;
    right: -40px;
}
.blog-post-section .blog-post .blog-post-image-wrapper .blog-post-image img {
    min-width: 100%;
    max-height: 500px;
    border-radius: 10px;
    object-fit: contain;
}
.blog-post-section .blog-post .blog-post-body {
    color: var(--text-dark-terniary);
    line-height: 1.8;
    max-width: 1024px;
    margin: 0 auto;
    text-align: justify;
}
.blog-post-section .blog-post-socials-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog-post-section .blog-post-socials-wrapper .social-icon {
    margin: 20px;
    font-size: 30px;
    cursor: pointer;
    color: var(--brand-primary-blue);
}
@media only screen and (max-width: 768px) {
    .blog-post-section .blog-post .blog-post-image-wrapper .blog-post-image::before,
    .blog-post-section .blog-post .blog-post-image-wrapper .blog-post-image::after {
        content: none;
    }
}
@media only screen and (max-width: 1024px) {
    .blog-post-section {
        padding: 20px;
    }
    .blog-post-section .back-button {
        margin: 0 auto 40px auto;
    }
}
</style>